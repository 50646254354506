import getConfig from "next/config";

export const classNames = function (...classes) {
  return classes.filter(Boolean).join(" ");
};

export const requestSubmit = (form) => {
  const fakeButton = document.createElement("button");
  fakeButton.type = "submit";
  fakeButton.style.display = "none";
  form.appendChild(fakeButton);
  fakeButton.click();
  fakeButton.remove();
};

export const fetcher = async (endpoint) => {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
  const API_URI = serverRuntimeConfig.URI || publicRuntimeConfig.URI;

  const res = await fetch(`${API_URI}/${endpoint}`);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (typeof window === "undefined" && !res.ok) {
    return null;
  } else if (typeof window !== "undefined" && !res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};
