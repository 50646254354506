import React, { useContext, useEffect, useState } from "react";
import { POST, useApi } from "../hook/useApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { ERROR, SUCCESS, ToastContext } from "../context/ToastContext";
import { Select } from "./Input/Select";
import { InputText } from "./Input/InputText";
import { Textarea } from "./Input/Textarea";
import { classNames } from "../utils/misc";

const subjectTypes = [
  { value: 1, label: "Demande d'informations" },
];

const schema = yup
  .object({
    type: yup
      .number()
      .oneOf(
        subjectTypes.map((val) => val.value),
        "Vous devez indiquer un type de demande valide."
      )
      .required("Merci de sélectionner une valeur"),
    email: yup
      .string()
      .email("Vous devez entrer une adresse mail valide")
      .required("Le champ email est requis."),
    message: yup.string().required("Le champ message est requis."),
    acceptTerms: yup
      .bool()
      .oneOf(
        [true],
        "Vous devez accepter les termes avant de pouvoir envoyer une demande de contact."
      ),
  })
  .required();

export const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const { data, callFetch, error, loading: loadingFetch } = useApi("contact");
  const { setToastVisibility, setToastTitle, setToastContent, setToastType } =
    useContext(ToastContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const recaptchaRef = React.useRef();

  const onSubmit = async (data) => {
    setLoading(true);
    data.recaptchaToken = await recaptchaRef.current.executeAsync();

    if (!data.name) {
      delete data.name;
    }
    if (!data.company) {
      delete data.company;
    }

    callFetch({ method: POST, body: data });
  };

  const formItemStyles = classNames(
    "px-2.5 py-1.5 w-full border border-gray-300 text-gray-900 placeholder:text-gray-900 focus:border-fel-purple focus:outline-none"
  );

  useEffect(() => {
    if (data !== null && !error) {
      setToastTitle("Mail envoyé !");
      setToastContent("Nos équipes s'occupent de votre demande");
      setToastType(SUCCESS);
      setToastVisibility(true);
    }
  }, [
    data,
    error,
    setToastTitle,
    setToastContent,
    setToastType,
    setToastVisibility,
  ]);

  useEffect(() => {
    if (error !== null) {
      setToastTitle("Erreur lors de l'envoi du mail");
      setToastContent("Merci de réessayer ultérieurement");
      setToastType(ERROR);
      setToastVisibility(true);
    }
  }, [error, setToastTitle, setToastContent, setToastType, setToastVisibility]);

  useEffect(() => {
    if (!loadingFetch) {
      setLoading(false);
    }

    if (!loadingFetch && data) {
      reset({
        type: undefined,
        name: undefined,
        company: undefined,
        email: "",
        acceptTerms: false,
        message: "",
      });
    }
  }, [loadingFetch, data, reset]);

  return (
    <div>
      <form
        className="grid grid-cols-1 gap-4 text-sm lg:grid-cols-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full lg:col-span-2">
          <Select
            name="type"
            control={control}
            options={subjectTypes}
            label="Type de demande"
            placeholder="Sélectionnez votre demande"
            errorMessage={errors.type?.message}
          />
        </div>
        <div className="w-full">
          <InputText
            name="name"
            register={register}
            label="Nom / Prénom"
            placeholder="Nom / Prénom"
            errorMessage={errors.name?.message}
          />
        </div>
        <div className="w-full">
          <InputText
            name="company"
            register={register}
            errorMessage={errors.company?.message}
            label="Société"
            placeholder="Société"
          />
        </div>
        <div className="w-full lg:col-span-2">
          <InputText
            name="email"
            register={register}
            errorMessage={errors.email?.message}
            label="Adresse mail"
            placeholder="Adresse mail *"
          />
        </div>
        <div className="w-full lg:col-span-2">
          <Textarea
            name="message"
            register={register}
            errorMessage={errors.message?.message}
            label="Message"
            placeholder="Message *"
            rows={4}
          />
        </div>
        <div className="w-full lg:col-span-2">
          <label htmlFor="acceptTerms">
            <input
              type="checkbox"
              id="acceptTerms"
              className="mr-2 h-3 w-3 border-gray-300 text-fel-purple focus:ring-1 focus:ring-fel-purple"
              {...register("acceptTerms")}
            />
            En soumettant ce formulaire, j&apos;accepte que les informations
            saisies soient utilisées pour me recontacter. *
          </label>
          {errors.acceptTerms?.message && (
            <p className="text-sm text-red-500">{errors.acceptTerms.message}</p>
          )}
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}
          className="absolute"
        />
        <div className="w-full text-center lg:col-span-2">
          <button
            disabled={loading}
            className="inline-block bg-fel-purple py-1 px-6 text-base font-semibold uppercase text-white"
            type="submit"
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};
