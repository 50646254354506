import React from "react";
import { useContext, useEffect, useState } from "react";
import { Dialog, Combobox } from "@headlessui/react";
import { ModalContext } from "../context/ModalContext";
import SearchIcon from "../public/images/icon-search.svg";
import { classNames } from "../utils/misc";
import { useRouter } from "next/router";

export default function Search() {
  const router = useRouter();
  const [query, setQuery] = useState(null);
  const [results, setResults] = useState([]);

  const { setModalIsOpen } = useContext(ModalContext);
  const [value, setValue] = useState();

  useEffect(() => {
    if (query) {
      fetch(`${process.env.NEXT_PUBLIC_API_URL}/search?query=${query}`).then(
        (res) =>
          res.json().then((data) => {
            setResults(data);
          })
      );
    } else {
      setResults(null);
    }
  }, [query]);

  useEffect(() => {
    if (value) {
      setModalIsOpen(false);
      setResults(null);
      router.replace(`/offers/show/${value._id}`);
    }
  }, [value, router, setModalIsOpen]);

  const handleChange = (e) => {
    if (query !== "") {
      setQuery(e.target.value);
    } else {
      setQuery(null);
    }
  };

  return (
    <>
      <div className="w-100 fixed inset-0 mt-16 flex h-max items-start justify-center sm:mt-24">
        <div className="relative z-50 h-full w-full max-w-lg transform px-4 transition-all">
          <div className="rounded-xl bg-white shadow-lg">
            <Dialog.Description
              as="div"
              className="max-h-full divide-y divide-gray-200 overflow-y-auto rounded-xl rounded-b-lg bg-white text-sm font-medium"
            >
              <Combobox value={value} onChange={setValue}>
                <div className="relative flex items-center pr-4 shadow-sm">
                  <Combobox.Input
                    className="mr-9 flex-auto appearance-none border-none bg-transparent py-4 pl-4 pr-12 text-base text-gray-600 placeholder-gray-500 focus:outline-none focus:ring-transparent sm:text-sm"
                    aria-label="Search offers"
                    onChange={handleChange}
                    placeholder="Rechercher une offre"
                  />
                  <SearchIcon className="h-6 stroke-[#b2b2b2] stroke-[6px]" />
                </div>
                <Combobox.Options static className="divide-y divide-gray-200">
                  {results &&
                    results.length > 0 &&
                    results.map((result) => (
                      <Combobox.Option
                        key={result._id}
                        value={result}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-gray-50 text-fel-purple"
                              : "text-gray-900",
                            "relative cursor-pointer select-none p-4 text-sm"
                          )
                        }
                      >
                        {result._source.label}
                      </Combobox.Option>
                    ))}
                </Combobox.Options>
                {query && !results && (
                  <li className="flex justify-between bg-gray-50 p-4 text-gray-600 hover:bg-gray-100">
                    Chargement en cours...
                  </li>
                )}
                {query && results && results.length === 0 && (
                  <div className="bg-gray-50 p-16 text-center">
                    <h2 className="mb-2 font-semibold text-gray-600">
                      Aucun résultat
                    </h2>
                  </div>
                )}
              </Combobox>
            </Dialog.Description>
          </div>
        </div>
      </div>
    </>
  );
}
