import React from "react";
import Image from "next/image";
import Facebook from "../../public/images/icon-facebook.svg";
import Instagram from "../../public/images/icon-instagram.svg";
import Linkedin from "../../public/images/icon-linkedin.svg";
import Twitter from "../../public/images/icon-twitter.svg";
import Link from "next/link";

export default function Footer() {
  return (
    <footer className="section bg-fel-purple text-white">
      <div className="section-container">
        <div className="mb-8 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-3">
          <div className="text-center lg:text-left">
            <p>
              <strong>
                UCIA/Foire de Châlons <br />
                Mess des entrepreneurs
              </strong>
            </p>
            <p>
              <a href="tel:33326682044">+33 (03) 26 68 20 44</a>
            </p>
            <div>
              <address>
                42, rue Grande Etape <br />
                BP 36 <br />
                51005 CHÂLONS EN CHAMPAGNE <br />
                Cedex
              </address>
            </div>
          </div>
          <div className="text-center lg:text-right xl:text-center">
            <div className="relative inline-block h-20 w-24 md:h-28 md:w-36">
              <Image
                src="/logo-white.svg"
                alt="Foire en ligne"
                layout="fill"
                objectFit="contain"
              />
            </div>
          </div>
          <div className="text-center lg:col-span-2 lg:text-left xl:col-span-1 xl:text-right">
            <h2 className="mb-1 text-xl">Suivez Nous !</h2>
            <div className="inline-flex space-x-2">
              <a href="https://www.facebook.com/foiredechalons" target="_blank">
                <Facebook className="w-8 fill-white transition hover:fill-fel-yellow" />
                <span className="sr-only">Facebook</span>
              </a>
              <a href="https://www.linkedin.com/company/foire-de-ch%C3%A2lons-officiel/" target="_blank">
                <Linkedin className="w-8 fill-white transition hover:fill-fel-yellow" />
                <span className="sr-only">LinkedIn</span>
              </a>
              <a href="https://www.instagram.com/foiredechalonsofficiel/" target="_blank">
                <Instagram className="w-8 fill-white transition hover:fill-fel-yellow" />
                <span className="sr-only">Instagram</span>
              </a>
              <a href="https://twitter.com/foiredechalons" target="_blank">
                <Twitter className="w-8 fill-white transition hover:fill-fel-yellow" />
                <span className="sr-only">Twitter</span>
              </a>
            </div>
          </div>
        </div>
        <div className="text-center lg:text-left xl:text-right">
          <p className="text-sm">
            Création : <strong>CHAMPAGNE-CREATION©</strong> - Tous droits
            réservés
            <Link href="/pages/mentions-legales">
              <a className="px-4 underline transition hover:text-fel-yellow">
                Mentions Légales
              </a>
            </Link>
            <Link href="/pages/cgu">
              <a className="underline transition hover:text-fel-yellow">CGU</a>
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}
