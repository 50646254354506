export default function NavbarSkeleton() {
  return (
    <>
      <div className="flex animate-pulse flex-row space-x-10 py-1">
        <div className="h-4 w-20 rounded bg-gray-300" />
        <div className="h-4 w-14 rounded bg-gray-300" />
        <div className="h-4 w-20 rounded bg-gray-300" />
        <div className="h-4 w-12 rounded bg-gray-300" />
        <div className="h-4 w-4 rounded bg-gray-300" />
      </div>
    </>
  );
}
