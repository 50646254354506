import React from "react";
import { ContactForm } from "../contactForm";

export const ContactBlock = () => {
  return (
    <section className="section" id="contact">
      <div className="section-container flex flex-col items-center">
        <h2 className="section-title mb-6 text-fel-purple">Nous contacter</h2>
        <div className="lg:max-w-md xl:max-w-lg">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};
