import Head from "next/head";

const Meta = ({ title, description, image }) => {
  let siteTitle = title ? `${title} | Foire en ligne` : `Foire en ligne`;
  let siteDescription = description
    ? description
    : `Des offres de la Foire de Châlons tout au long de l'année`;

  siteTitle = siteTitle.replace(/(<([^>]+)>)/gi, "");
  siteDescription = siteDescription.replace(/(<([^>]+)>)/gi, "");

  return (
    <Head>
      <title>{siteTitle}</title>
      <meta key="title" name="title" content={siteTitle} />
      <meta key="description" name="description" content={siteDescription} />
      <meta property="og:type" content="website" />
      <meta key="og:title" property="og:title" content={siteTitle} />
      <meta
        key="og:description"
        property="og:description"
        content={siteDescription}
      />
      {image ? (
        <meta key="og:image" property="og:image" content={image} />
      ) : (
        <>
          <meta property="og:image" content="/share.jpg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
        </>
      )}
    </Head>
  );
};

export default Meta;
