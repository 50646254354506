import React, { useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useSWR from "swr";
import { CATEGORIES } from "../../utils/endpoints";
import NavbarSkeleton from "../general/navbarSkeleton";
import { classNames, fetcher } from "../../utils/misc";
// import Search from "../search";
// import SearchIcon from "../../public/images/icon-search.svg";
import { ModalContext } from "../../context/ModalContext";

const CategoriesMenu = ({ invert = false }) => {
  const router = useRouter();
  const { modalIsOpen, setModalIsOpen, setModalContent } =
    useContext(ModalContext);
  const { data: categories } = useSWR(CATEGORIES, fetcher, {
    revalidateOnFocus: false,
  });

  const isActive = (path) => {
    const regExp = new RegExp(`^${path}`);
    return regExp.test(router.asPath);
  };

  const linkClasses = (path) => {
    return classNames(
      invert
        ? "text-white hover:border-white"
        : "text-fel-purple hover:border-fel-purple",
      isActive(path)
        ? invert
          ? "border-white"
          : "border-fel-purple"
        : "border-transparent",
      "font-semibold border-b-2 transition-all"
    );
  };

  return categories ? (
    <>
      {categories.map(({ name, identifier }) => {
        let path = `/offers/${encodeURIComponent(identifier)}`;

        return (
          <Link key={name} href={path}>
            <a className={linkClasses(path)}>{name}</a>
          </Link>
        );
      })}
      <Link href="/offers/equipagro">
        <a className={linkClasses("/offers/equipagro")}>Hall Equip Agro</a>
      </Link>
      <Link href="/offers/promo">
        <a
          className={classNames(
            isActive("/offers/promo")
              ? "border-fel-purple bg-fel-purple hover:border-fel-yellow hover:bg-fel-yellow"
              : "border-fel-yellow bg-fel-yellow hover:border-fel-purple hover:bg-fel-purple",
            "inline-block border px-1 font-bold uppercase text-white transition-all"
          )}
        >
          Promo
        </a>
      </Link>
      {/*<button
        onClick={() => {
          setModalIsOpen(true);
          setModalContent(<Search />);
        }}
        role="search"
        aria-label="Dans les offres actives"
        className="hidden md:block"
      >
        <SearchIcon
          className={classNames(
            invert ? "stroke-white" : "stroke-fel-purple",
            modalIsOpen
              ? "stroke-fel-yellow"
              : invert
              ? "stroke-white"
              : "stroke-fel-purple",
            "h-5 w-5 stroke-[6px] hover:stroke-fel-yellow"
          )}
        />
      </button>*/}
    </>
  ) : (
    <NavbarSkeleton />
  );
};

export default CategoriesMenu;
