import React from "react";

export default function HeaderSkeleton() {
  return (
    <div className="flex animate-pulse flex-row space-x-20">
      <div className="h-52 w-52 cursor-pointer rounded-full bg-white opacity-90 transition hover:scale-110">
        <div className="flex h-full flex-col items-center justify-center space-y-2">
          <div className="h-32 w-32 rounded-full bg-gray-200" />
          <div className="h-4 w-24 rounded bg-gray-200" />
        </div>
      </div>
      <div className="relative">
        <div className="absolute top-1/2 right-full mr-4 h-0 w-12 border-b-8 border-dotted border-white opacity-90">
          &nbsp;
        </div>
        <div className="h-52 w-52 cursor-pointer rounded-full bg-white opacity-90 transition hover:scale-110">
          <div className="flex h-full flex-col items-center justify-center space-y-2">
            <div className="h-32 w-32 rounded-full bg-gray-200" />
            <div className="h-4 w-24 rounded bg-gray-200" />
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute top-1/2 right-full mr-4 h-0 w-12 border-b-8 border-dotted border-white opacity-90">
          &nbsp;
        </div>
        <div className="h-52 w-52 cursor-pointer rounded-full bg-white opacity-90 transition hover:scale-110">
          <div className="flex h-full flex-col items-center justify-center space-y-2">
            <div className="h-32 w-32 rounded-full bg-gray-200" />
            <div className="h-4 w-24 rounded bg-gray-200" />
          </div>
        </div>
      </div>
    </div>
  );
}
