import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import { Controller } from "react-hook-form";
import { classNames } from "../../utils/misc";

export const Select = ({
  name,
  control,
  errorMessage,
  options,
  label,
  placeholder,
}) => {
  return (
    <>
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <Listbox value={value} onChange={onChange}>
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button
                      className={classNames(
                        open && "border-fel-purple outline-none",
                        "w-full border border-gray-300 px-2.5 py-1.5 text-left text-gray-900 placeholder:text-gray-900 focus:border-fel-purple focus:outline-none"
                      )}
                    >
                      <span
                        className={`block truncate text-sm ${
                          value && value !== "" ? "" : "text-gray-900"
                        }`}
                      >
                        {value &&
                        value !== "" &&
                        options.find((val) => val.value === value)
                          ? options.find((val) => val.value === value)["label"]
                          : placeholder}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-900"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        static
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto border border-fel-purple bg-white shadow-lg focus:outline-none"
                      >
                        {options.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              classNames(
                                active && "bg-gray-100",
                                "relative cursor-default select-none px-2.5 py-1.5 text-sm text-gray-900"
                              )
                            }
                            value={option.value}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected
                                      ? "pl-6 font-medium"
                                      : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {option.label}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-2.5 text-fel-purple">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            {errorMessage && (
              <p className="text-sm text-red-500">{errorMessage}</p>
            )}
          </>
        )}
      />
    </>
  );
};
