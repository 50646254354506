import React, { useContext } from "react";
import Link from "next/link";
import Image from "next/image";
import { classNames } from "../utils/misc";
import News from "../public/images/icon-news.svg";
import Contact from "../public/images/icon-contact.svg";
import Info from "../public/images/icon-info.svg";
import Expo from "../public/images/icon-expo.svg";
import MobileNavbar from "./mobileNavbar";
import Header from "./general/Header";
import CategoriesMenu from "./menu/Categories";
import Search from "./search";
//import SearchIcon from "../public/images/icon-search.svg";
import { ModalContext } from "../context/ModalContext";

export const Navbar = ({ isHome = false }) => {
  const { setModalIsOpen, setModalContent } = useContext(ModalContext);

  const IconClasses = classNames(
    isHome ? "fill-white stroke-white" : "fill-fel-purple stroke-fel-purple",
    "mb-2 h-10 w-10 stroke-[2px] transition group-hover:stroke-fel-yellow group-hover:fill-fel-yellow"
  );

  return (
    <>
      <div className={classNames(!isHome && "bg-white pb-2 shadow-lg")}>
        <div className="w-full px-4 pt-4 lg:px-12 lg:pt-8">
          <div className="relative mb-4 flex justify-between md:mb-10">
            <div className="relative hidden md:flex">
              <Link href="https://forms.sbc28.com/61c352814b4b811a82e30382/0D6BbexTSySVM2U1W80tMA/-Sx-kSSBRsSYISl-qxastw/form.html" passHref>
                <a
                  className={classNames(
                    isHome ? "text-white" : "text-fel-purple",
                    "group flex cursor-pointer flex-col items-center py-3 text-sm"
                  )}
                  target='_blank'
                >
                  <News className={IconClasses} />
                  <span className="leading-tight">
                    <span className="hidden lg:block">
                      Inscription à la newsletter
                    </span>
                    <span className="block lg:hidden" role="presentation">
                      Newsletter
                    </span>
                  </span>
                </a>
              </Link>
            </div>
            <div className="relative md:absolute md:inset-0 md:left-1/2 md:-translate-x-1/2 md:transform">
              <div className="flex flex-col md:items-center">
                <div className="relative h-20 w-24 lg:h-28 lg:w-36">
                  {isHome ? (
                    <h1>
                      <Image
                        src="/logo-white.svg"
                        alt="Foire en ligne"
                        layout="fill"
                        objectFit="contain"
                        priority
                      />
                    </h1>
                  ) : (
                    <Link href="/">
                      <a>
                        <Image
                          src="/logo-purple.svg"
                          alt="Foire en ligne"
                          layout="fill"
                          objectFit="contain"
                          priority
                        />
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="relative hidden md:flex">
              <div
                className={classNames(
                  isHome ? "text-white" : "text-fel-purple",
                  "flex flex-row space-x-4 py-3 text-sm lg:space-x-8"
                )}
              >
                <Link href="/pages/a-propos">
                  <a className="group flex cursor-pointer flex-col items-center">
                    <Info className={IconClasses} />
                    <span className="leading-tight">À-propos</span>
                  </a>
                </Link>
                <Link href="/espace-exposants">
                  <a className="group flex cursor-pointer flex-col items-center">
                    <Expo className={IconClasses} />
                    <span className="leading-tight">Espace exposants</span>
                  </a>
                </Link>
                <Link href="/#contact" scroll={false}>
                  <a className="group flex cursor-pointer flex-col items-center">
                    <Contact className={IconClasses} />
                    <span className="leading-tight">Contact</span>
                  </a>
                </Link>
              </div>
            </div>
            <div className="flex flex-row items-center space-x-3 md:hidden">
              {/*<button
                onClick={() => {
                  setModalIsOpen(true);
                  setModalContent(<Search />);
                }}
                role="search"
                aria-label="Dans les offres actives"
              >
                <SearchIcon
                  className={classNames(
                    isHome ? "stroke-white" : "stroke-fel-purple",
                    "h-8 w-8 stroke-[4px] hover:stroke-fel-yellow"
                  )}
                />
              </button>*/}
              <MobileNavbar isHome={isHome} />
            </div>
          </div>
          <div className="max-w-screen:2xl hide-scrollbar relative -mx-4 overflow-x-auto whitespace-nowrap md:mx-0 md:w-full">
            <div className="flex space-x-4 px-4 sm:justify-center md:space-x-8">
              <CategoriesMenu invert={isHome} />
            </div>
          </div>
        </div>
      </div>
      {isHome && <Header />}
    </>
  );
};
