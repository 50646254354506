import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import News from "../public/images/icon-news.svg";
import Contact from "../public/images/icon-contact.svg";
import Info from "../public/images/icon-info.svg";
import Expo from "../public/images/icon-expo.svg";
import { classNames } from "../utils/misc";
import Link from "next/link";

export default function MobileNavbar({ isHome }) {
  return (
    <div className="z-50 w-full">
      <Menu>
        <Menu.Button
          className="w-full"
          aria-label="Afficher le menu secondaire"
        >
          <MenuIcon
            className={classNames(
              isHome ? "stroke-white" : "stroke-fel-purple",
              "h-8 w-8 hover:stroke-fel-yellow"
            )}
          />
        </Menu.Button>
        <Transition>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 h-full w-full bg-fel-purple bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Menu.Items className="fixed right-0 top-0 h-full w-80 bg-fel-purple p-4 shadow-lg focus:outline-none">
              <div className="absolute top-0 right-0 p-4">
                <Menu.Button aria-label="Masquer le menu secondaire">
                  <XIcon className="w-8 fill-white stroke-white" />
                </Menu.Button>
              </div>
              <div className="divide-y divide-white py-1 pt-8">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link href="/">
                        <a
                          className={classNames(
                            active ? "text-fel-yellow" : "text-white",
                            "group flex w-full items-center rounded-md px-2 py-2 text-sm"
                          )}
                        >
                          <span>Accueil</span>
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                </div>
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <Link href="/pages/a-propos">
                        <a
                          className={`${
                            active
                              ? "stroke-white text-fel-yellow"
                              : "text-white"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <Info className="h-8 w-8 fill-white stroke-white stroke-[2px]" />
                          <span className="ml-4">A propos</span>
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link href="/become_exhibitor" as="/espace-exposants">
                        <a
                          className={`${
                            active
                              ? "fill-white stroke-white text-fel-yellow"
                              : "text-white"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <Expo className="h-8 w-8 fill-white stroke-white stroke-[2px]" />
                          <span className="ml-4">Espace exposants</span>
                        </a>
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Button>
                    {({ active }) => (
                      <Link href="/#contact" scroll={false}>
                        <a
                          className={`${
                            active
                              ? "stroke-white text-fel-yellow"
                              : "text-white"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <Contact className="h-8 w-8 fill-white stroke-white stroke-[2px]" />
                          <span className="ml-4">Contact</span>
                        </a>
                      </Link>
                    )}
                  </Menu.Button>
                </div>
                <div className="px-1 py-1">
                  <Menu.Button>
                    {({ active }) => (
                      <Link href="https://forms.sbc28.com/61c352814b4b811a82e30382/0D6BbexTSySVM2U1W80tMA/-Sx-kSSBRsSYISl-qxastw/form.html" passHref>
                        <a
                          className={`${
                            active
                              ? "stroke-white text-fel-yellow"
                              : "text-white"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          target="_blank"
                        >
                          <News className="h-8 w-8 fill-white stroke-white stroke-[2px]" />
                          <span className="ml-4">
                            Inscription à la newsletter
                          </span>
                        </a>
                      </Link>
                    )}
                  </Menu.Button>
                </div>
              </div>
            </Menu.Items>
          </Transition.Child>
        </Transition>
      </Menu>
    </div>
  );
}
