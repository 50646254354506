import Modal from "./Modal";
import { Navbar } from "./navbar";
import NewsletterBlock from "./general/newsletterBlock";
import { ContactBlock } from "./general/contactBlock";
import Footer from "./general/footer";
import Toast from "./general/Toast";
import { classNames } from "../utils/misc";
import Meta from "./Meta";

export const Layout = ({ isHome, children }) => (
  <>
    <Meta />
    <div className={classNames(isHome && "bg-home", "min-h-screen bg-white")}>
      {isHome && (
        <div className="absolute h-96 w-full bg-gradient-to-b from-blue-500 to-transparent" />
      )}
      <Toast />
      <Navbar isHome={isHome} />
      <main className="w-full">
        {children}
        <Modal />
      </main>
      <NewsletterBlock isHome={isHome} />
      <ContactBlock />
      <Footer />
    </div>
  </>
);
