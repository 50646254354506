import React from "react";
import { classNames } from "../../utils/misc";
import Link from 'next/link'

export default function NewsletterBlock({ isHome }) {
  return (
    <section
      className={classNames(
        !isHome && "-mt-20",
        "section -skew-y-3 bg-gray-200 bg-[url('/nl-background.jpg')] bg-cover bg-center bg-no-repeat pb-16",
      )}
      id="newsletter"
    >
      <div className="section-container skew-y-3 text-fel-purple">
        <h2 className="section-title text-center">Restez informé</h2>
        <h2 className="mt-2 text-center uppercase tracking-wider">
          Inscription à la Newsletter
        </h2>
        <div className="mt-4 flex justify-center">
          <Link href="https://forms.sbc28.com/61c352814b4b811a82e30382/0D6BbexTSySVM2U1W80tMA/-Sx-kSSBRsSYISl-qxastw/form.html" passHref>
            <a className="bg-fel-purple py-1 px-6 text-base font-semibold uppercase text-white" target='_blank'>
              S'inscrire
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
}
