import { useContext } from "react";
import { ModalContext } from "../context/ModalContext";
import { Dialog } from "@headlessui/react";

export default function Modal() {
  const { modalIsOpen, setModalIsOpen, modalContent, setModalContent } =
    useContext(ModalContext);

  return (
    <Dialog
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      className="fixed inset-0 z-50 overflow-auto"
    >
      <div className="container mx-auto h-full lg:max-w-screen-lg lg:py-8">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="relative flex flex-col items-center">
          {modalContent}
        </div>
      </div>
    </Dialog>
  );
}
