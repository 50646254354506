import React from "react";

export const InputText = ({
  register,
  name,
  label,
  placeholder,
  errorMessage,
}) => {
  return (
    <>
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <input
        className="w-full border border-gray-300 px-2.5 py-1.5 text-sm text-gray-900 placeholder:text-gray-900 focus:border-fel-purple focus:shadow-none focus:outline-none focus:ring-0"
        type="text"
        {...register(name)}
        placeholder={placeholder}
      />
      {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
    </>
  );
};
