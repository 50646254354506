import Actualites from "../../public/images/icon-actualites.svg";
import Produits from "../../public/images/icon-produits.svg";
import Evenements from "../../public/images/icon-evenements.svg";
import Equipagro from "../../public/images/icon-equipagro.svg";
import Link from "next/link";
import React from "react";
import HeaderSkeleton from "./HeaderSkeleton";
import useSWR from "swr";
import { fetcher } from "../../utils/misc";
import { INDICATORS, CATEGORIES } from "../../utils/endpoints";

export default function Header() {
  const { data, error } = useSWR(INDICATORS, fetcher, {
    revalidateOnFocus: false,
  });

  const { data: categories } = useSWR(CATEGORIES, fetcher, {
    revalidateOnFocus: false,
  });

  return (
    <>
      <div className="section-container relative mx-auto flex flex-col items-center justify-center pb-20 text-white md:pb-0">
        <div className="pt-16 pb-8">
          <p className="section-title text-center text-4xl">
            Des offres de la Foire de Châlons tout au long de l&apos;année
          </p>
        </div>
        <div className="flex w-full flex-row justify-center space-x-20 font-semibold md:space-x-36">
          <div className="flex flex-col items-center text-lg md:text-2xl">
            <span className="whitespace-nowrap text-4xl md:text-6xl">
              - {data?.offersCount} -
            </span>
            <span>offres</span>
          </div>
          <div className="flex flex-col items-center text-lg md:text-2xl">
            <span className="whitespace-nowrap text-4xl md:text-6xl">
              - {data?.usersCount} -
            </span>
            <span>exposants</span>
          </div>
        </div>
        <div className="hidden space-x-10 py-10 font-semibold md:flex md:flex-row lg:space-x-20">
          {categories ? (
              <>
                {
                  categories.map((item, index) => (
                    <div className="relative" key={index}>
                      {index > 0 && (
                        <div className="absolute top-1/2 right-full mr-1 -mt-1 h-0 w-8 border-b-4 border-dotted border-white opacity-90 lg:-mt-2 lg:mr-4 lg:w-12 lg:border-b-8">
                          &nbsp;
                        </div>
                      )}
                      <Link key={index} href={`/offers/${item.identifier}`}>
                        <a className="block h-52 w-52 cursor-pointer rounded-full bg-white bg-opacity-90 transition hover:scale-110">
                          <div className="flex h-full flex-col items-center justify-center space-y-1">
                            {item.identifier === "actualites" && (
                              <Actualites className="h-24 stroke-fel-yellow stroke-[4px]" />
                            )}
                            {item.identifier === "produits" && (
                              <Produits className="h-24 stroke-fel-yellow stroke-[4px]" />
                            )}
                            {item.identifier === "evenements" && (
                              <Evenements className="h-24 fill-fel-yellow stroke-fel-yellow" />
                            )}
                            <span className="mt-2.5 inline-block text-xl text-fel-purple">
                              {item.name}
                            </span>
                          </div>
                        </a>
                      </Link>
                    </div>
                  ))
                }
                <div className="relative">
                  <div className="absolute top-1/2 right-full mr-1 -mt-1 h-0 w-8 border-b-4 border-dotted border-white opacity-90 lg:-mt-2 lg:mr-4 lg:w-12 lg:border-b-8">
                    &nbsp;
                  </div>
                  <Link href={`/offers/equipagro`}>
                    <a className="block h-52 w-52 cursor-pointer rounded-full bg-white bg-opacity-90 transition hover:scale-110">
                      <div className="flex h-full flex-col items-center justify-center space-y-1">
                        <Equipagro className="h-24 fill-fel-yellow stroke-fel-yellow" />
                        <span className="mt-2.5 inline-block text-xl text-fel-purple">
                    Equip'Agro
                  </span>
                      </div>
                    </a>
                  </Link>
                </div>
              </>
          ) : (
            <HeaderSkeleton />
          )}
        </div>
      </div>
    </>
  );
}
